export const dataCours=[
    // {
    //     id:0,
    // },
    {
    id:1,
    id2:21,
    name:"Formation Python",
    name2:"Jonathan Roux",
    img:"../../images/informatique/python_jonathan.jpg",
    top : "-2%",
    left:"-1%",
    rotate:"rotate(-15deg)",
    comp1:"Bases de Python",
    comp2:"Algorythmie, fonctions, boucles, conditions",
    comp3:"Listes, collections",
    comp4:"Programmation Orientée Objet"
    },
    {
    id:2,
    id2:22,
    name:"Formation Python",
    name2:"Thibault Houdon",
    img:"../../images/informatique/python_thibault.jpg",
    top : "18%",
    left:"12%",
    rotate:"rotate(5deg)",
    comp1:"Consolidation de la formation de Jonathan",
    comp2:"Environnements virtuels",
    comp3:"Terminal de commande",
    comp4:"Bases data science avec Jupyter Lab"
    },
    {
    id:3,
    id2:22,
    name:"Formation HTML / CSS",
    name2:"Louis Nicolas",
    img:"../../images/informatique/html_css_believemy.jpg",
    top : "5%",
    left:"25%",
    rotate:"rotate(-10deg)",
    comp1:"Les bases HTML 5 / CSS 3",
    comp2:"La mise en place de formulaires",
    comp3:"La structure d'un site",
    comp4:"L'intégration de vidéos et musiques"
    },
    {
    id:4,
    id2:22,
    name:"Formation Front End",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/html_css_enzo.jpg",
    top : "40%",
    left:"20%",
    rotate:"rotate(-5deg)",
    comp1:"La consolidation de la formation de Louis",
    comp2:"L'entrée dans le Javascript",
    comp3:"La responsivité",
    comp4:"Découverte des animations"
    },
    {
    id:5,
    id2:22,
    name:"Formation Javascript",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/projets_js.jpg",
    top : "45%",
    left:"35%",
    rotate:"rotate(5deg)",
    comp1:"L'appel à des API",
    comp2:"Boucler sur des objets/tableaux",
    comp3:"L'introductin à la POO en JS",
    comp4:"Les EventListener"
    },
    {
    id:6,
    id2:22,
    name:"Formation GreenSock",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/gsap.jpg",
    top : "70%",
    left:"28%",
    rotate:"rotate(-5deg)",
    comp1:"La création d'animation grâce à cette librairie",
    comp2:"Les TimeLine",
    comp3:"Le Scrolltrigger",
    comp4:"Les easings"
    },
    {
    id:7,
    id2:22,
    name:"Formation Animations au Scroll",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/animations.jpg",
    top : "70%",
    left:"13%",
    rotate:"rotate(10deg)",
    comp1:"L'utilisation de Rellax",
    comp2:"La création d'effets Parallax",
    comp3:"Le scroll horizontal",
    comp4:"L'animation sans la saturation !"
    },
    {
    id:8,
    id2:22,
    name:"React de A à Z",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/react.jpg",
    top : "70%",
    left:"48%",
    rotate:"rotate(0deg)",
    comp1:"La découverte de cette librairie",
    comp2:"Les States, les props",
    comp3:"Les Hooks",
    comp4:"Les API de contexte"
    },
    {
    id:9,
    id2:22,
    name:"10 projets React",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/projets_react.jpg",
    top : "77%",
    left:"62%",
    rotate:"rotate(5deg)",
    comp1:"La consolidation de la formation de React",
    comp2:"Les animations en React",
    comp3:"Les formulaires",
    comp4:"La mise en place d'une App 'Complexe'"
    },
    {
    id:10,
    id2:22,
    name:"Formation Nextjs",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/next.jpg",
    top : "78%",
    left:"78%",
    rotate:"rotate(-15deg)",
    comp1:"La découverte de ce framework",
    comp2:"Le système de routage",
    comp3:"L'optimisation des images",
    comp4:"La création d'API "
    },
    {
    id:11,
    id2:22,
    name:"Git et Github",
    name2:"Enzo Ustariz",
    img:"../../images/informatique/git.jpg",
    top : "50%",
    left:"-1%",
    rotate:"rotate(-10deg)",
    comp1:"Les principales commandes Git",
    comp2:"Les principales commandes Github",
    comp3:"Le travail collaboratif",
    comp4:"NB : j'aurais dû faire cette formation plus tôt !!!"
    },
    {
    id:12,
    id2:22,
    name:"La Programmation Orienté Objet",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/poo.jpg",
    top : "35%",
    left:"50%",
    rotate:"rotate(-15deg)",
    comp1:"Bases de ce paradigme",
    comp2:"La visibilité (attibuts et fonctions)",
    comp3:"Héritage / Polymorphisme",
    comp4:"Encapsulation / Abstraction"
    },
    {
    id:13,
    id2:22,
    name:"101 requêtes SQL",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/101_requetes.jpg",
    top : "5%",
    left:"50%",
    rotate:"rotate(5deg)",
    comp1:"Bases : SELECT, FROM, AS, WHERE",
    comp2:"Jointures : JOIN, LEFT/RIGHT JOIN...",
    comp3:"Requêtes imbriquées",
    comp4:"Gestion tables lignes"
    },
    {
    id:14,
    id2:22,
    name:"La structure MVC",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/structure_mvc.jpg",
    top : "2%",
    left:"70%",
    rotate:"rotate(0deg)",
    comp1:"Structure maintenable.",
    comp2:"Routage avec connexion à une BD (PDO).",
    comp3:"Création d'éléments réutilisables (footer, menu...).",
    comp4:"Gestion des messages d'alertes."
    },
    {
    id:15,
    id2:22,
    name:"La gestion de comptes",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/gestion_comptes.jpg",
    top : "40%",
    left:"72%",
    rotate:"rotate(-5deg)",
    comp1:"Gestionnaire de comptes utilisateurs.",
    comp2:"Système de connexion.",
    comp3:"Gestion des rôles.",
    comp4:"Système d'administration."
    },
    {
    id:16,
    id2:22,
    name:"Site sécurisé",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/site_secur.jpg",
    top : "20%",
    left:"62%",
    rotate:"rotate(10deg)",
    comp1:"Partie administration.",
    comp2:"Utilisation d'une bdd.",
    comp3:"Architecture MVC.",
    comp4:"Refonte d'un site statique."
    },
    {
    id:17,
    id2:22,
    name:"Site MVC",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/site_mvc.jpg",
    top : "20%",
    left:"82%",
    rotate:"rotate(10deg)",
    comp1:"Architecture d'un site.",
    comp2:"MVC et POO.",
    comp3:"Connexion BDD / SQL.",
    comp4:"CRUD d'éléments du site."
    },
    {
    id:18,
    id2:22,
    name:"PHP / SQL & React",
    name2:"Matthieu Gaston",
    img:"../../images/informatique/php_react.jpg",
    top : "60%",
    left:"85%",
    rotate:"rotate(0deg)",
    comp1:"Création d'une app fullstack.",
    comp2:"Mise en plac ed'un serveur PHP.",
    comp3:"Connexion à une API Rest.",
    comp4:"Administration et CRUD de la BDD."
    },



]