import React from "react";

export default function Cours(props) {



  return (
    <div 
    className="picto" 
    >
      <img src={props.img} alt="cours udemy" />
        
    </div>
  );
}
